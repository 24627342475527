<template>
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button
        v-for="(item, index) in Kv"
        :key="index"
        type="button"
        :data-bs-target="'#carouselExampleIndicators'"
        :data-bs-slide-to="index"
        :class="{ active: index === 0 }"
        :aria-label="'Slide ' + (index + 1)"
        :aria-current="index === 0 ? 'true' : null"
      ></button>
    </div>
    
    <div class="carousel-inner">
      <div 
        v-for="(item, index) in Kv"
        :key="index"
        :class="['carousel-item', { active: index === 0 }]"
      >
        <img :src="$imgUrl + item.image" class="d-block w-100" :alt="'Slide ' + (index + 1)">
      </div>
    </div>

    <div class="swp">
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
    name: 'FullScreenCarousel',
    data() {
        return {
            Kv: [], // 初始化为空数组
        };
    },
    created() {
        this.fetchKvData();
    },
    methods:{
        // 获取KV数据
        async fetchKvData() {
            try {
                const params = {
                    position: 'index_kv'
                };
                const response = await this.$api.get('/api/kv', { params });
                console.log(response.data.content);
                this.Kv = response.data.content;
            } catch (error) {9
                console.error('Failed to load data', error);
            }
        },
    }
};
</script>

<style scoped>
.carousel,
.carousel-inner,
.carousel-item,
.carousel-item img {
    height: 100vh; /* 全屏高度 */
}

.carousel-item img {
    object-fit: cover; /* 图片覆盖整个容器 */
}
</style>
