<!-- src/App.vue -->
<template>
  <div id="app">
    <router-view></router-view> <!-- 渲染路由匹配的组件 -->
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
.aboutBtn:hover {
    color: #fff;
    background-color: #5266A5; /* 鼠标悬停时的背景色 */
}
.hover-div {
  text-decoration: none; /* 默认情况下没有下划线 */
  cursor: pointer; /* 将鼠标悬停时的指针变为手形 */
}
/* Your global styles here */
.fixed-top-add{
  position: sticky;
    top: 0;
    background-color: #fff;
    text-align: center;
    z-index: 9999;
}
.about-top {
    padding-top: 30px;
}
.breadcrumb-item{
  color: #5266A5;
}
.breadcrumb-item+.breadcrumb-item::before{
 color: #5266A5!important;
}
.breadcrumb{
   
}
@media (min-width: 1200px) {
    .container {
        max-width: 1440px !important;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-webkit-keyframes fadeInUp50 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 50px, 0);
        -ms-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}

@keyframes fadeInUp50 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 50px, 0);
        -ms-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px)
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
        -ms-transform: translateY(-50px);
        transform: translateY(-50px)
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-webkit-keyframes fadeInLeft50 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100px, 0, 0);
        -ms-transform: translate3d(-100px, 0, 0);
        transform: translate3d(-100px, 0, 0);
        -moz-transform: translate3d(-100px, 0, 0);
        -o-transform: translate3d(-100px, 0, 0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}

@keyframes fadeInLeft50 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100px, 0, 0);
        -ms-transform: translate3d(-100px, 0, 0);
        transform: translate3d(-100px, 0, 0);
        -moz-transform: translate3d(-100px, 0, 0);
        -o-transform: translate3d(-100px, 0, 0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        -ms-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        -ms-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}

@-webkit-keyframes fadeInRight50 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(50px, 0, 0);
        -ms-transform: translate3d(50px, 0, 0);
        transform: translate3d(50px, 0, 0);
        -moz-transform: translate3d(50px, 0, 0);
        -o-transform: translate3d(50px, 0, 0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}

@keyframes fadeInRight50 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(50px, 0, 0);
        -ms-transform: translate3d(50px, 0, 0);
        transform: translate3d(50px, 0, 0);
        -moz-transform: translate3d(50px, 0, 0);
        -o-transform: translate3d(50px, 0, 0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}

@-webkit-keyframes h-line {
    0% {
        transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform-origin: 50% 100%;
        -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
        -moz-transform-origin: 50% 100%;
        -o-transform-origin: 50% 100%;
        opacity: 1
    }

    20% {
        transform: scale(1, .01);
        -webkit-transform: scale(1, .01);
        -ms-transform: scale(1, .01);
        -moz-transform: scale(1, .01);
        -o-transform: scale(1, .01);
        transform-origin: 50% 100%;
        -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
        -moz-transform-origin: 50% 100%;
        -o-transform-origin: 50% 100%;
        opacity: 1
    }

    30% {
        transform: scale(1, .01);
        -webkit-transform: scale(1, .01);
        -ms-transform: scale(1, .01);
        -moz-transform: scale(1, .01);
        -o-transform: scale(1, .01);
        transform-origin: 50% 100%;
        -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
        -moz-transform-origin: 50% 100%;
        -o-transform-origin: 50% 100%;
        opacity: 0
    }

    31% {
        transform: scale(1, .01);
        -webkit-transform: scale(1, .01);
        -ms-transform: scale(1, .01);
        -moz-transform: scale(1, .01);
        -o-transform: scale(1, .01);
        transform-origin: 50% 0;
        -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
        -moz-transform-origin: 50% 0;
        -o-transform-origin: 50% 0;
        opacity: 0
    }

    50% {
        transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform-origin: 50% 0;
        -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
        -moz-transform-origin: 50% 0;
        -o-transform-origin: 50% 0;
        opacity: 1
    }

    100% {
        transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform-origin: 50% 100%;
        -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
        -moz-transform-origin: 50% 100%;
        -o-transform-origin: 50% 100%;
        opacity: 1
    }
}

@keyframes h-line {
    0% {
        transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform-origin: 50% 100%;
        -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
        -moz-transform-origin: 50% 100%;
        -o-transform-origin: 50% 100%;
        opacity: 1
    }

    20% {
        transform: scale(1, .01);
        -webkit-transform: scale(1, .01);
        -ms-transform: scale(1, .01);
        -moz-transform: scale(1, .01);
        -o-transform: scale(1, .01);
        transform-origin: 50% 100%;
        -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
        -moz-transform-origin: 50% 100%;
        -o-transform-origin: 50% 100%;
        opacity: 1
    }

    30% {
        transform: scale(1, .01);
        -webkit-transform: scale(1, .01);
        -ms-transform: scale(1, .01);
        -moz-transform: scale(1, .01);
        -o-transform: scale(1, .01);
        transform-origin: 50% 100%;
        -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
        -moz-transform-origin: 50% 100%;
        -o-transform-origin: 50% 100%;
        opacity: 0
    }

    31% {
        transform: scale(1, .01);
        -webkit-transform: scale(1, .01);
        -ms-transform: scale(1, .01);
        -moz-transform: scale(1, .01);
        -o-transform: scale(1, .01);
        transform-origin: 50% 0;
        -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
        -moz-transform-origin: 50% 0;
        -o-transform-origin: 50% 0;
        opacity: 0
    }

    50% {
        transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform-origin: 50% 0;
        -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
        -moz-transform-origin: 50% 0;
        -o-transform-origin: 50% 0;
        opacity: 1
    }

    100% {
        transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform-origin: 50% 100%;
        -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
        -moz-transform-origin: 50% 100%;
        -o-transform-origin: 50% 100%;
        opacity: 1
    }
}

@-webkit-keyframes slideInUp {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes slideInUp {
    0% {
        -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible
    }

    100% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.animated.flip {
    -webkit-backface-visibility: visible;
    -ms-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip
}

.animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}

.fadeInUp50 {
    -webkit-animation-name: fadeInUp50;
    animation-name: fadeInUp50
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}

.fadeInLeft50 {
    -webkit-animation-name: fadeInLeft50;
    animation-name: fadeInLeft50
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}

.fadeInRight50 {
    -webkit-animation-name: fadeInRight50;
    animation-name: fadeInRight50
}

.com_bg {
    background: no-repeat center top
}

.h-line {
    -webkit-animation-name: h-line;
    animation-name: h-line
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp
}
</style>
