<template>
  <div class="aboutCourse">
    <div class="container">
      <div class="about-nav">
        <ul class="nav" id="myTab" role="tablist">
          <li class="nav-item" role="presentation" v-for="(tab, index) in tabs" :key="index">
            <a class="addYearList" :class="{ active: activeTabAbout === index }" @click="selectTab(index)">
              {{ tab.start_year }}-{{ tab.end_year }}
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="myTabContent">
        <div class="content-time" v-for="(data, index) in DataView" :key="index">
          <div class="event_time">
            {{ data.event_time.split('-')[0] }}
            <!-- {{ data.event_time }} -->
            <div class="event_tag"></div>
          </div>
          <div class="title_cn">
              {{ data.event_time.split('-')[1] }}月
            <!-- {{ currentLanguage === 'zh' ? data.title_cn : data.title_en }} -->
          </div>
          <div class="description_cn">
            {{ currentLanguage === 'zh' ? data.description_cn : data.description_en }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
    name: 'aboutCourse',
    data() {
        return {
            currentLanguage: this.$i18n.locale,
            activeTabAbout: 0, // 默认显示的标签页
            activeTab: 'about', // 'home', 'about', 'news', 'contact', 'other'
            tabs: [], // 用于存储动态获取的标签数据
            loading: false,
            pageIndex: 0,
            pageSize: 20,
            DataView: ''
        };
    },
    created() {
        this.fetchTabsData();
    },
    watch: {
        '$i18n.locale': function (newLocale) {
            this.currentLanguage = newLocale;
        }
    },
    methods: {

        async fetchTabsData() {
            this.loading = true;
            try {
                const response = await this.$api.get('/api/history/year', {

                });
                console.log(response.data)
                this.tabs = response.data;
                this.selectTab(0);
            } catch (error) {
                this.error = 'Failed to load data';
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        async selectTab(tabId) {
            console.log(tabId)
            this.activeTabAbout = tabId;
            this.loading = true;

            try {
                const params = {
                    hy_id: this.tabs[tabId].hy_id,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    sort: 'DESC'
                };
                const response = await this.$api.get('/api/history/list', {
                    params
                });
                console.log(response.data)
                this.DataView = response.data.row;

            } catch (error) {
                this.error = 'Failed to load data';
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

    }
};
</script>

<style scoped>

.presentation{
    
}
.about-nav {
    padding: 20px 0;
}
.title_cn{
    padding-bottom: 5PX;
}
.addYearList {
    font-family: Impact;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;
    text-decoration: none;
    font-variation-settings: "opsz"auto;
    color: #D8D8D8;
    text-align: center;
}

.tab-content {
    padding: 30px 0;

}

.nav-item {
    flex: 1;
    text-align: center;
}

.active {
    font-size: 2.25rem;
    font-weight: bolder;
    border-bottom: 3px solid #8291C1 !important;
    padding-bottom: 5px;
    color: #5266A5;
}

.nav {
    align-items: flex-end;
}

.content-time {
    border-left: 1px solid #5266A5;
    padding: 0 0 30px 30px;
    position: relative;
}

.event_tag {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #F5F5F5;
    border: 3px solid #5266A5;
    position: absolute;
    left: -7px;
    top: 30px;
}

.event_time {
    font-family: Impact;
    font-size: 60px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #8291C1;
    padding-bottom: 1.5rem;
}

.title_cn {
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;

    font-variation-settings: "opsz"auto;
    font-feature-settings: "kern"on;
    color: #3D3D3D;
}

.description_cn {
    font-size: 0.8rem;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;

    font-variation-settings: "opsz"auto;
    font-feature-settings: "kern"on;
    color: #3D3D3D;
}
</style>
