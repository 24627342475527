<!-- src/views/AboutView.vue -->
<template>
<div>

    <div class="container-fluid about-bac">
        <NavBar :activeTab="activeTab" />
        <!-- <img src="../assets/about/image@2x.png" alt="关于紫江" /> -->
    </div>
    <div class="container-fluid fixed-top-add">
        <div class="container about-top ">
            <div class="about-title">
                <div class="">
                    <div class="breadCrumb-all">
                        <div class="container breadCrumb-a">
                            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">首页</li>
                                    <li class="breadcrumb-item" aria-current="page">产业布局</li>
                                    <li class="breadcrumb-item" aria-current="page">高新区</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="about-nav">
                    <ul class="nav" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="" :class="{ activeA: activeTabAboutA === 'home' }" id="home-tab" href="#home" @click="selectTab('home')">
                                {{ $t('qitalei') }}
                                <!-- {{ currentLanguage === 'zh' ? DataView[0].title_cn : DataView[0].title_en }} -->
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="" :class="{ activeA: activeTabAboutA === 'profile' }" id="profile-tab" href="#profile" @click="selectTab('profile')">
                                {{ $t('yanfa') }}
                                <!-- {{ currentLanguage === 'zh' ? DataView[1].title_cn : DataView[1].title_en }} -->
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="" :class="{ activeA: activeTabAboutA === 'contact' }" id="contact-tab" href="#contact" @click="selectTab('contact')">
                                {{ $t('daxue') }}
                                <!-- {{ currentLanguage === 'zh' ? DataView[2].title_cn : DataView[2].title_en }} -->
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="" :class="{ activeA: activeTabAboutA === 'zr' }" id="zr-tab" href="#zr" @click="selectTab('zr')">
                                {{ $t('peitao') }}
                                <!-- {{ currentLanguage === 'zh' ? DataView[3].title_cn : DataView[3].title_en }} -->
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="" :class="{ activeA: activeTabAboutA === 'cx' }" id="zr-tab" href="#cx" @click="selectTab('cx')">
                                {{ $t('chuangxin') }}
                                <!-- {{ currentLanguage === 'zh' ? DataView[3].title_cn : DataView[3].title_en }} -->
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container" id="home">
        <div class="AppLayoutView">
            <div class="AppLayout-title">
                {{ currentLanguage === 'zh' ? DataView[0].title_cn : DataView[0].title_en }}
            </div>
            <div class="PressCenterDetails-content AppLayout-View" v-html="currentLanguage === 'zh' ?  DataView[0].content_cn :  DataView[0].content_en"></div>
            <!-- <div class="AppLayout-View">
                <p>
                    紫竹高新区成立于2002年，是目前中国唯一一家以民营经济为主体运作的国家高新区，也是上海科创中心建设重要承载区。高新区始终以“生态、人文、科技”为发展理念，通过体制机制创新，推进校区、社区、园区“三区联动”，努力成为铂金产业服务商和战略新兴产业的引领者。主导产业包括信息软件、数字视听、生命科学、智能制造、航空电子、新能源与新材料等六大类产业。<br /><br />
                    2022年紫竹成立20年之际，高新区财政总收入突破100亿元，年营业收入1075亿元，在全国国家级高新区中综合排名第9，实现了建园时“给我二十年，给你一座科技城”的承诺。这座拥有15万高素质人群的科技新城，正成为上海乃至国家重要的科技创新出发地、原始创新策源地、自主创新主阵地。<br /><br />
                </p>
            </div> -->
        </div>
        <div>
            <div class="ContactUs-contact">
                <div class="ContactUs-border "> {{ currentLanguage === 'zh' ? DataView[1].title_cn : DataView[1].title_en }}</div>
            </div>
            <div class="PressCenterDetails-content AppLayout-View" v-html="currentLanguage === 'zh' ?  DataView[1].content_cn :  DataView[1].content_en"></div>
            <!-- <div class="AppLayout-View">
                <p>
                    紫竹形成了以信息软件、数字视听、生命科学、智能制造、航空电子、新能源与新材料为支柱的产业结构，吸引了微软、英特尔、可口可乐、印孚瑟斯、中国商飞、中广核集团、东软软件等国内外知名企业的地区总部、研发机构和高端制造入驻，成为全球高端研发和技术集聚区。<br /><br />
                </p>
            </div> -->
        </div>
    </div>

    <div class="container" style="padding-bottom: 30px;" id="profile">
        <div class="row">
            <div class="col" :class="{ 'large': hovered === 1, 'small': hovered !== 1 && hovered !== 0 }" @mouseover="hovered = 1" @mouseleave="hovered = 0">
                <!-- 第一列内容 -->
                <div class="content contentBac" :style="`background: linear-gradient(69deg, rgba(0, 0, 0, 0.88) 0%, rgba(82, 102, 165, 0.44) 100%), url(${imgUrl}${currentLanguage === 'zh' ? DataView[1].carousel_cn[0].image[0] : DataView[1].carousel_en[0].image[0]});`">
                    <div class="content-title"> {{ currentLanguage === 'zh' ? DataView[1].carousel_cn[0].title
 : DataView[1].carousel_en[0].title }}</div>
                    <div class="content-tag"></div>
                    <div class="content-view" v-if="hovered === 1">
                        {{ currentLanguage === 'zh' ? DataView[1].carousel_cn[0].content : DataView[1].carousel_en[0].content }}
                    </div>
                </div>
            </div>
            <div class="col" :class="{ 'large': hovered === 2, 'small': hovered !== 2 && hovered !== 0 }" @mouseover="hovered = 2" @mouseleave="hovered = 0">
                <!-- 第二列内容 -->
                <div class="content contentBac" :style="`background: linear-gradient(69deg, rgba(0, 0, 0, 0.88) 0%, rgba(82, 102, 165, 0.44) 100%), url(${imgUrl}${currentLanguage === 'zh' ? DataView[1].carousel_cn[1].image[0] : DataView[1].carousel_en[1].image[0]});`">
                    <div class="content-title"> {{ currentLanguage === 'zh' ? DataView[1].carousel_cn[1].title : DataView[1].carousel_en[1].title }}</div>
                    <div class="content-tag"></div>
                    <div class="content-view" v-if="hovered === 2">
                        {{ currentLanguage === 'zh' ? DataView[1].carousel_cn[1].content : DataView[1].carousel_en[1].content }}
                    </div>
                </div>
            </div>
            <div class="col" :class="{ 'large': hovered === 3, 'small': hovered !== 3 && hovered !== 0 }" @mouseover="hovered = 3" @mouseleave="hovered = 0">
                <!-- 第三列内容 -->
                <div class="content contentBac" :style="`background: linear-gradient(69deg, rgba(0, 0, 0, 0.88) 0%, rgba(82, 102, 165, 0.44) 100%), url(${imgUrl}${currentLanguage === 'zh' ? DataView[1].carousel_cn[2].image[0] : DataView[1].carousel_en[2].image[0]});`">
                    <div class="content-title"> {{ currentLanguage === 'zh' ? DataView[1].carousel_cn[2].title : DataView[1].carousel_en[2].title }}</div>
                    <div class="content-tag"></div>
                    <div class="content-view" v-if="hovered === 3">
                        {{ currentLanguage === 'zh' ? DataView[1].carousel_cn[2].content : DataView[1].carousel_en[2].content }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" id="contact">
        <div>
            <div class="ContactUs-contact">
                <div class="ContactUs-border "> {{ currentLanguage === 'zh' ? DataView[2].title_cn : DataView[2].title_en }}</div>
            </div>
            <div class="PressCenterDetails-content AppLayout-View" v-html="currentLanguage === 'zh' ?  DataView[2].content_cn :  DataView[2].content_en"></div>
            <!-- <div class="ContactUs-contact">
                <div class="ContactUs-border ">大学校区</div>
            </div>
            <div class="AppLayout-View">
                <p>
                    大学校区为高新区产业发展提供了充沛的人才、智力、师资、科研等支撑。高新区以上海交通大学和华东师范大学为核心，引入国内外著名高等学府的科研机构和分支院校。 <br /><br />
                    2012年，紫竹国际教育园区成立，园区紧邻紫竹高新区，致力于引进世界一流大学入驻举办高水平本科和研究生学历教育及开展高层次国际学术交流和科研合作，开设优质的国际课程体系，打造具有全球影响力的国际教育集聚地和产学研深度融合示范区。与一流大学已共建创办了上海交大-南加州大学文化创意产业学院、华东师范大学亚欧商学院、华东师范大学-海法大学转化科学与技术联合研究院三所中外合作办学二级学院。园区内的圣华紫竹学院开设了A Level、IBDP、AP三大主流国际高中课程体系。<br /><br />
                </p>
            </div> -->
        </div>
    </div>
    <!-- <div class="container">
        <div class="row about-55">
            <div class="col-md-4">
                <img src="@/assets/about/about-55.png" alt="关于紫江" />
            </div>
            <div class="col-md-4">
                <img src="@/assets/about/about-6.png" alt="关于紫江" />
            </div>
            <div class="col-md-4">
                <img src="@/assets/about/about-7.png" alt="关于紫江" />
            </div>
        </div>
    </div> -->
    <div class="container" id="zr">
        <div class="ContactUs-contact">
            <div class="ContactUs-border "> {{ currentLanguage === 'zh' ? DataView[3].title_cn : DataView[3].title_en }}</div>
        </div>
        <div class="PressCenterDetails-content AppLayout-View" v-html="currentLanguage === 'zh' ?  DataView[3].content_cn :  DataView[3].content_en"></div>
        <!-- <div>
            <div class="ContactUs-contact">
                <div class="ContactUs-border ">配套区</div>
            </div>
            <div class="AppLayout-View">
                <p>
                    紫竹高新区拥有住宅房产、教育培训、医疗服务、健康养老、休闲娱乐、商业机构等全方位的配套服务与设施， 为人们匠心精工铸就“一生之城”。
                </p>
            </div>
        </div> -->
    </div>
    <div class='' id="cx">
        <div>
            <div class="nav nav-tabs container" id="myTab" role="tablist">
                <div class="nav-item" role="presentation" v-for="(data, index) in currentLanguage === 'zh' ? DataView[3].carousel_cn : DataView[3].carousel_en" :key="index">
                    <div class="nav-link" :class="{ active: activeTabAbout === index }" :id="'tab-' + index" data-bs-toggle="tab" :href="'#content-' + index" role="tab" :aria-controls="'content-' + index" :aria-selected="activeTabAbout === index" @click="selectTab(index)">
                        <div class="addLine" :class="{ addLineactive: activeTabAbout === index }">{{ data.title }}</div>
                    </div>
                </div>
            </div>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade" v-for="(data, index) in currentLanguage === 'zh' ? DataView[3].carousel_cn : DataView[3].carousel_en" :class="{ show: activeTabAbout === index, active: activeTabAbout === index }" :id="'content-' + index" role="tabpanel" :aria-labelledby="'tab-' + index" :key="index">
                    <div class="container">
                        <div class="row layout-flex">
                            <div class="col-md-7">
                                <div class="zz">
                                    {{ currentLanguage === 'zh' ? DataView[3].carousel_cn[index].content : DataView[3].carousel_en[index].content }}
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"></li>
                                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
                                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div v-for="(image, imgIndex) in currentLanguage === 'zh' ? DataView[3].carousel_cn[index].image : DataView[3].carousel_en[index].image" :key="'image-' + index + '-' + imgIndex" :class="{ 'carousel-item': true, active: imgIndex === 0 }">
                                            <!-- {{image}} -->
                                            <img :src="$imgUrl + image" class="d-block w-100" alt="...">
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div>
            <div class="ContactUs-contact">
                <div class="ContactUs-border "> {{ currentLanguage === 'zh' ? DataView[4].title_cn : DataView[4].title_en }}</div>
            </div>
            <div class="PressCenterDetails-content AppLayout-View" v-html="currentLanguage === 'zh' ?  DataView[4].content_cn :  DataView[4].content_en"></div>
            <!-- <div class="ContactUs-contact">
                <div class="ContactUs-border ">创新投资</div>
            </div>
            <div class="AppLayout-View">
                <p>
                    高新区已建立起以紫竹创业孵化器、紫竹创业中心、小苗基金为核心主体的创新创业生态系统，打造12000平方米规模的“国家级科技企业孵化器”，为创业者持续提供创新创业服务。
                </p>
            </div> -->
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col" :class="{ 'large': hoveredAdd === 1, 'small': hoveredAdd !== 1 && hoveredAdd !== 0 }" @mouseover="hoveredAdd = 1" @mouseleave="hoveredAdd = 0">
                <!-- 第一列内容 -->
                <div class="content contentBac" :style="`background: linear-gradient(69deg, rgba(0, 0, 0, 0.88) 0%, rgba(82, 102, 165, 0.44) 100%), url(${imgUrl}${currentLanguage === 'zh' ? DataView[4].carousel_cn[0].image[0] : DataView[4].carousel_en[0].image[0]});`">
                    <div class="content-title">
                        {{ currentLanguage === 'zh' ? DataView[4].carousel_cn[0].title
 : DataView[4].carousel_en[0].title }}
                    </div>
                    <div class="content-tag"></div>
                    <div class="content-view" v-if="hoveredAdd === 1">
                        {{ currentLanguage === 'zh' ? DataView[4].carousel_cn[0].content : DataView[4].carousel_en[0].content }}
                    </div>
                </div>
            </div>
            <div class="col" :class="{ 'large': hoveredAdd === 2, 'small': hoveredAdd !== 2 && hoveredAdd !== 0 }" @mouseover="hoveredAdd = 2" @mouseleave="hoveredAdd = 0">
                <!-- 第二列内容 -->
                <div class="content contentBac" :style="`background: linear-gradient(69deg, rgba(0, 0, 0, 0.88) 0%, rgba(82, 102, 165, 0.44) 100%), url(${imgUrl}${currentLanguage === 'zh' ? DataView[4].carousel_cn[1].image[0] : DataView[4].carousel_en[1].image[0]});`">
                    <div class="content-title"> {{ currentLanguage === 'zh' ? DataView[4].carousel_cn[1].title
 : DataView[4].carousel_en[1].title }}</div>
                    <div class="content-tag"></div>
                    <div class="content-view" v-if="hoveredAdd === 2">
                        {{ currentLanguage === 'zh' ? DataView[4].carousel_cn[1].content : DataView[4].carousel_en[1].content }}
                    </div>
                </div>
            </div>
            <div class="col" :class="{ 'large': hoveredAdd === 3, 'small': hoveredAdd !== 3 && hoveredAdd !== 0 }" @mouseover="hoveredAdd = 3" @mouseleave="hoveredAdd = 0">
                <!-- 第三列内容 -->
                <div class="content contentBac" :style="`background: linear-gradient(69deg, rgba(0, 0, 0, 0.88) 0%, rgba(82, 102, 165, 0.44) 100%), url(${imgUrl}${currentLanguage === 'zh' ? DataView[4].carousel_cn[2].image[0] : DataView[4].carousel_en[2].image[0]});`">
                    <div class="content-title"> {{ currentLanguage === 'zh' ? DataView[4].carousel_cn[2].title
 : DataView[4].carousel_en[2].title }}</div>
                    <div class="content-tag"></div>
                    <div class="content-view" v-if="hoveredAdd === 3">
                        {{ currentLanguage === 'zh' ? DataView[4].carousel_cn[2].content : DataView[4].carousel_en[2].content }}
                    </div>
                </div>
            </div>
            <div class="col" :class="{ 'large': hoveredAdd === 4, 'small': hoveredAdd !== 4 && hoveredAdd !== 0 }" @mouseover="hoveredAdd = 4" @mouseleave="hoveredAdd = 0">
                <!-- 第三列内容 -->
                <div class="content contentBac" :style="`background: linear-gradient(69deg, rgba(0, 0, 0, 0.88) 0%, rgba(82, 102, 165, 0.44) 100%), url(${imgUrl}${currentLanguage === 'zh' ? DataView[4].carousel_cn[3].image[0] : DataView[4].carousel_en[3].image[0]});`">
                    <div class="content-title"> {{ currentLanguage === 'zh' ? DataView[4].carousel_cn[3].title
 : DataView[4].carousel_en[3].title }}</div>
                    <div class="content-tag"></div>
                    <div class="content-view" v-if="hoveredAdd === 4">
                        {{ currentLanguage === 'zh' ? DataView[4].carousel_cn[3].content : DataView[4].carousel_en[3].content }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container logo-all">
        <div class="container logo-all">
            <div class="row">
                <!-- 默认显示前6个logo -->
                <div v-for="(logo, index) in DataView[5].logo.slice(0, showAll ? DataView[5].logo.length : 6)" :key="index" class="col-6 col-md-4 col-lg-2 logo-view">
                    <img :src=" $imgUrl + logo" :alt="logo" />
                </div>
            </div>
            <div class="text-center mt-3">
                <button v-if="!showAll" @click="showAllLogos" class="btn btn-primary moreBtn">更多合作伙伴</button>
            </div>
        </div>
    </div>
    <FooterBar />
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterBar from '@/components/FooterBar.vue';
export default {
    name: 'PageLayout',
    components: {
        NavBar,
        FooterBar,
    },
    data() {
        return {
            hovered: 0,
            hoveredAdd: 0,
            showAll: false,
            activeTabAbout: 0,
            activeTabAboutA:'home',
            activeTab: 'other',
            DataView: '',
            currentLanguage: this.$i18n.locale,
            imgUrl: 'http://zjdev.dzhcn.top/assets/'
            //'home', 'about', 'news', 'contact', 'other'
        };
    },
    created() {
        console.log(this.currentLanguage)
        this.getPage(2)
    },
    watch: {
        '$i18n.locale': function (newLocale) {
            this.currentLanguage = newLocale;
        }
    },
    methods: {
        // selectTab(tabId) {
        //     this.activeTabAbout = tabId;
        // },
         selectTab(tabId) {
            console.log(tabId);
            this.activeTabAboutA = tabId;
        },
        showAllLogos() {
            this.showAll = true;
        },
        async getPage(typeInt) {
            const params = {
                type: typeInt // 使用传入的 typeInt 参数
            };
            try {
                const response = await this.$api.get('/api/page', {
                    params
                });
                console.log(response.data);
                this.DataView = response.data.row;
                console.log(this.DataView)
            } catch (error) {
                this.error = 'Failed to load data';
                console.error(error);
            } finally {
                // 可以在这里添加任何需要在请求完成后执行的逻辑
            }
        }
    }
};
</script>

<style scoped>
li {
    list-style-type: none;
}

.container-fluid {
    padding: 0;
}

.carousel-item {
    width: 100%;
    border-radius: 10px;
}

.about-bac {
    position: relative;
    background: url(@/assets/appLayout/image@2x.png) 0px 0px / contain no-repeat fixed;

    height: 260px;
}

.layout-flex {
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}

.zz {
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
}

.tab-content {
    background-color: #5266A5;
}

.activeTabAbout {}

.active {
    color: #fff !important;
    background-color: #5266A5 !important;

}
.activeA{
     border-bottom: 3px solid #8291C1;
    padding-bottom: 12px;
}

.nav-link {
    border: none !important;
    color: #5266A5;
}

.nav-tabs {
    justify-content: space-around;
}

.nav-link {
    min-width: 220px;
    padding: 17px 0;
}
.nav-item a {
    text-decoration: none;
    font-size: 16px;
    margin: 0 30px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    color: #3D3D3D;
}
.addLine {
    border-left: 5px solid #33A37A;
    padding-left: 20px;
    margin-left: 20px;
}

.addLineactive {
    border-left: 5px solid #FFFFFF;
}

.active-Bc {}

.breadcrumb {
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #5266A5;
}

.breadcrumb-item+.breadcrumb-item::before {
    color: #5266A5 !important;
}

.AppLayout-title {
    font-size: 34px;
    font-weight: normal;
    line-height: normal;
    margin-top: 25px;
    display: inline-block;
    letter-spacing: 0em;
    border-bottom: 6px solid #8291C1;
}

.AppLayout-View {
    padding: 20px 0;
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    text-align: justify;
    /* 浏览器可能不支持 */
    letter-spacing: 0em;
}

.breadCrumb-a {
 
    border-bottom: 1px solid #8291C1;
}

.logo-view {
    padding: 10px;
    text-align: center;
}

.logo-view img {
    max-width: 100%;
    height: auto;
}

.text-center {
    text-align: center;
}

.moreBtn {
    width: 218px;
    height: 64px;
    border-radius: 33px;
    opacity: 1;

    background: #FFFFFF;
    color: #5266A5;
    box-sizing: border-box;
    border: 1px solid #5266A5;
}

.logo-all {
    padding: 30px;
}

.ContactUs-contact {
    padding: 10px 0 30px;
    font-size: 24px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #3D3D3D;

}

.ContactUs-border {
    border-left: 6px solid #8291C1;
    padding-left: 15px;
}

.row {
    display: flex;
}

.col {
    flex: 1;
    min-width: 0;
    /* 防止列太小 */
    transition: flex-grow 0.3s ease;
}

.col.large {
    flex-grow: 2;
}

.col.small {
    flex-grow: 0.5;
}

.content {
    padding: 20px;
    background-color: #f0f0f0;
    text-align: center;
    border: 1px solid #ccc;
    transition: all 0.3s ease;
}

.contentBac {
    height: 350px;
    border-radius: 10px;

}

.content-title {
    color: #FFFFFF;
    font-size: 24px;
    text-align: left;
}

.content-tag {
    width: 30px;
    height: 10px;
    background-color: #33A37A;
    margin: 30px 0 10px;
}

.content-view {
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    color: #FFFFFF;
    text-align: left;
}

.about-55 img {
    width: 100%;
}
.about-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #8291C1;
}
</style>
