<template>
<div>
   
    <div class="container-fluid about-bac">
        <NavBar :activeTab="activeTab" />
        <!-- <img src="../assets/about/image@2x.png" alt="关于紫江" /> -->
    </div>
    <div class="container-fluid fixed-top-add">
    <div class="breadCrumb-all">
        <div class="container breadCrumb-a">
            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">{{ $t('home') }}</li>
                    <li class="breadcrumb-item" aria-current="page">新闻中心</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <div class="PressCenterDetails">
                    <div class="PressCenterDetails-left">
                        <div class="PressCenterDetails-title">
                            {{ currentLanguage === 'zh' ? DataView.title_cn : DataView.title_en }}
                        </div>
                        <div class="release_time">
                            {{ DataView.release_time }}
                        </div>
                    </div>

                    <div class="PressCenterDetails-content" v-html="currentLanguage === 'zh' ? DataView.content_cn : DataView.content_en">
                    </div>
                   
                </div>

            </div>
             <div class="col-md-3">
                <div class="ContactUs">
                    <div class="ContactUs-left">
                        <div class="ContactUs-contact">
                            <div class="ContactUs-border ">{{ $t('contact') }}</div>
                        </div>
                        <div class="ContactUs-site ContactUs-text">
                            <img class="footer-site" src="@/assets/me/dizhi.png" alt="地址" />
                            {{ $t('dizhi') }}</div>
                        <div class="ContactUs-call ContactUs-text">
                            <img class="footer-site" src="@/assets/me/call.png" alt="" />
                            021-54161688</div>
                        <div class="ContactUs-em ContactUs-text">
                            <img class="footer-site" src="@/assets/me/em.png" alt="" />
                            201199</div>
                        <div class="ContactUs-qr">
                            <img src="@/assets/me/qr.png" alt="" />
                        </div>
                    </div>

                </div>
             </div>
        </div>

    </div>

    <FooterBar />
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterBar from '@/components/FooterBar.vue';

export default {
    name: 'PressCenterDetails',
    components: {
        NavBar,
        FooterBar,
    },
    data() {
        return {
            currentLanguage: this.$i18n.locale,
            activeTab: 'news',
            tid: '',
            DataView: {},
            error: null,
        };
    },
    mounted() {
        this.tid = this.$route.query.tid;
        this.Getdetails(this.tid);
    },
    watch: {
        '$i18n.locale'(newLocale) {
            this.currentLanguage = newLocale;
        },
        '$route.query.tid'(newTid) {
            this.tid = newTid;
            this.Getdetails(newTid);
        }
    },
    methods: {
        async Getdetails(newTid) {
            try {
                const params = {
                    nid: newTid
                };
                const response = await this.$api.get('/api/news/details', {
                    params
                });
                this.DataView = response.data;
            } catch (error) {
                this.error = 'Failed to load data';
                console.error(error);
            }
        }
    }
};
</script>

<style scoped>
.about-bac {
    position: relative;
    background: url(@/assets/cen2.png) 0px 0px / contain no-repeat fixed;
    height: 260px;
}
.release_time{
    padding-top: 10px;
}
.breadCrumb-a {
    padding: 25px 0 0px;
    border-bottom: 1px solid #8291C1;
}

.PressCenterDetails-title {
    font-size: 1.5rem;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #3D3D3D;
    margin-top: 20px;
}

.PressCenterDetails-content {}

.error-message {
    color: red;
    font-size: 16px;
}

.containerBac {
    background-color: #F5F5F5;
}

.release_time {
    font-size: 0.875rem;
    color: #5266A5;
}

.PressCenterDetails-left {
    border-left: 6px solid #8291C1;
    padding-left: 15px;
}

.PressCenterDetails-content {
    padding: 30px 0;
}
.ContactUs-contact {
    padding: 30px 0;
    font-size: 24px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #3D3D3D;

}

.ContactUs-border {
    border-left: 6px solid #8291C1;
    padding-left: 15px;
}

.ContactUs-site img {
    width: 16px;
    height: 22px;
    vertical-align: sub;
    margin-right: 10px;
}

.ContactUs-call img {
    width: 18px;
    height: 18px;
    vertical-align: sub;
    margin-right: 10px;
}

.ContactUs-em img {
    vertical-align: sub;
    height: 18px;
    margin-right: 10px;
}

.ContactUs-text {
    font-size: 1rem;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    color: #3D3D3D;
    padding-bottom: 17px;
}

.ContactUs-qr {
    text-align: center;
}

.ContactUs-qr img {

    width: 100%;
    opacity: 1;

}

.ContactUs {
    background: #F5F5F5;
    padding: 30px;
}
</style>
