// src/router/index.js
import Vue from 'vue';
import Router from 'vue-router';
import index from '../views/index.vue';
import AboutView from '../views/AboutView.vue';
import ContactUs from '../views/ContactUs.vue';
import AppLayout from '../views/AppLayout.vue';
import PressCenter from '../views/PressCenter.vue';
import PressCenterDetails from '../views/PressCenterDetails.vue';
import PageLayout from '../views/PageLayout.vue';
import MyCourse from '../views/MyCourse.vue';
import MyDuty from '../views/MyDuty.vue';
import realEstate from '../views/realEstate.vue';


Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: index,
    },
    {
      path: '/about',
      name: 'AboutView',
      component: AboutView,
    },
    {
      path: '/ContactUs',
      name: 'ContactUs',
      component: ContactUs,
    },
    {
      path: '/appLayout',
      name: 'AppLayout',
      component: AppLayout,
    },
    {
      path: '/PressCenter',
      name: 'PressCenter',
      component: PressCenter,
    },
    {
      path: '/PressCenterDetails',
      name: 'PressCenterDetails',
      component: PressCenterDetails,
    },
    {
      path: '/PageLayout',
      name: 'PageLayout',
      component: PageLayout,
    },
    {
      path: '/History',
      name: 'MyCourse',
      component: MyCourse,
    }, 
     {
       path: '/ESG',
       name: 'MyDuty',
       component: MyDuty,
    }, {
       path: '/realEstate',
       name: 'realEstate',
       component: realEstate,
    },
    
    // 添加其他路由配置
  ],
});
