<!-- src/views/AboutView.vue -->
<template>
<div>

    <div class="container-fluid about-bac">
        <NavBar :activeTab="activeTab" />
        <!-- <img src="../assets/about/image@2x.png" alt="关于紫江" /> -->
    </div>
    <div class="container-fluid fixed-top-add">
        <div class="breadCrumb-all">
            <div class="container breadCrumb-a">
                <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">首页</li>
                        <li class="breadcrumb-item" aria-current="page">产业布局</li>
                        <li class="breadcrumb-item" aria-current="page">制造业</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="AppLayoutView">
            <div class="AppLayout-title">
                {{ currentLanguage === 'zh' ? DataView.title_cn : DataView.title_en }}
            </div>
            <div class="PressCenterDetails-content AppLayout-View" v-html="currentLanguage === 'zh' ? DataView.content_cn : DataView.content_en"></div>
            <!-- <div class="AppLayout-View">
                <p>
                    紫江，创始于1981年，是一家根植于上海的民营投资控股集团。<br /><br />
                    紫江之名，取自发源地附近一条“紫港”的江流，也寓意紫江将不断汇聚力量，迈向更广阔的发展空间。<br /><br />
                    紫江创始人沈雯，现任上海紫江（集团）有限公司董事长、总裁，上海紫江企业集团股份有限公司董事长，上海紫竹高新区（集团）有限公司董事长、总经理，是第十届、十一届、十二届全国政协委员。<br /><br />
                    紫江从包装业起家，目前投资领域主要以制造业和高新区为主。制造业领域，以包装业务为核心的上海紫江企业集团股份有限公司于1999年上市；高新区领域，主要从事紫竹国家高新技术产业开发区、房地产及酒店等的开发与管理。作为上海最早成立党组织的民营企业之一，紫江成立40多年来，以“产业报国、科教兴国”为己任，并积极投身教育事业和科创领域，致力于为区域繁荣发展贡献已力。<br /><br />
                </p>
            </div> -->
        </div>
    </div>
    <div>
        <div>
            <div class="nav nav-tabs container" id="myTab" role="tablist">
                <div class="nav-item" role="presentation" v-for="(data, index) in currentLanguage === 'zh' ? DataView.carousel_cn : DataView.carousel_en" :key="index">
                    <div class="nav-link" :class="{ active: activeTabAbout === index }" :id="'tab-' + index" data-bs-toggle="tab" :href="'#content-' + index" role="tab" :aria-controls="'content-' + index" :aria-selected="activeTabAbout === index" @click="selectTab(index)">
                        <div class="addLine" :class="{ addLineactive: activeTabAbout === index }">{{ data.title }}</div>
                    </div>
                </div>
            </div>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade" v-for="(data, index) in currentLanguage === 'zh' ? DataView.carousel_cn : DataView.carousel_en" :class="{ show: activeTabAbout === index, active: activeTabAbout === index }" :id="'content-' + index" role="tabpanel" :aria-labelledby="'tab-' + index" :key="index">
                    <div class="container">
                        <div class="row layout-flex">
                            <div class="col-md-7">
                                <div class="zz">
                                    {{ currentLanguage === 'zh' ? DataView.carousel_cn[index].content : DataView.carousel_en[index].content }}
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"></li>
                                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
                                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div v-for="(image, imgIndex) in currentLanguage === 'zh' ? DataView.carousel_cn[index].image : DataView.carousel_en[index].image" :key="'image-' + index + '-' + imgIndex" :class="{ 'carousel-item': true, active: imgIndex === 0 }">
                                            <!-- {{image}} -->
                                            <img :src="$imgUrl + image" class="d-block w-100" alt="...">
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container logo-all">
        <div class="container logo-all">
            <div class="row">
                <!-- 默认显示前6个logo -->
                <div v-for="(logo, index) in DataView.logo.slice(0, showAll ? DataView.logo.length : 6)" :key="index" class="col-6 col-md-4 col-lg-2 logo-view">
                    <img :src=" $imgUrl + logo" :alt="logo" />
                </div>
            </div>
            <div class="text-center mt-3">
                <button v-if="!showAll" @click="showAllLogos" class="btn btn-primary moreBtn">更多合作伙伴</button>
            </div>
        </div>
    </div>
    <FooterBar />
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterBar from '@/components/FooterBar.vue';
export default {
    name: 'appLayout',
    components: {
        NavBar,
        FooterBar,
    },
    data() {
        return {
            activeTabAbout: 0, // 默认选中第一个标签页
            showAll: false,
            currentLanguage: this.$i18n.locale,
            activeTab: 'other',
            //'home', 'about', 'news', 'contact', 'other'
            DataView: ''
        };
    },
    created() {
        console.log(this.currentLanguage)
        this.getPage(1)
    },
    watch: {
        '$i18n.locale': function (newLocale) {
            this.currentLanguage = newLocale;
        }
    },
    methods: {
        selectTab(index) {
            this.activeTabAbout = index;
        },
        showAllLogos() {
            this.showAll = true;
        },
        //获取页面详细
        async getPage(typeInt) {
            const params = {
                type: typeInt // 使用传入的 typeInt 参数
            };
            try {
                const response = await this.$api.get('/api/page', {
                    params
                });
                console.log(response.data);
                this.DataView = response.data.row[0];
                console.log(this.DataView)
            } catch (error) {
                this.error = 'Failed to load data';
                console.error(error);
            } finally {
                // 可以在这里添加任何需要在请求完成后执行的逻辑
            }
        }

    }
};
</script>

<style scoped>
li {
    list-style-type: none;
}

.container-fluid {
    padding: 0;
}

.carousel-item {

    border-radius: 10px;
}

.about-bac {
    background: url(@/assets/appLayout/image@2x.png) 0px 0px / contain no-repeat fixed;
    position: relative;
    height: 260px;
}

.layout-flex {
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}

.zz {
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
}

.tab-content {
    background-color: #5266A5;
}

.activeTabAbout {}

.active {
    color: #fff !important;
    background-color: #5266A5 !important;

}

.nav-link {
    border: none !important;
    color: #5266A5;
}

.nav-tabs {
    justify-content: space-around;
}

.nav-link {
    width: 220px;
    padding: 17px 0;
}

.addLine {
    border-left: 5px solid #33A37A;
    padding-left: 20px;
    margin-left: 20px;
}

.addLineactive {
    border-left: 5px solid #FFFFFF;
}

.active-Bc {}

.breadcrumb {
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #5266A5;
}

.breadcrumb-item+.breadcrumb-item::before {
    color: #5266A5 !important;
}

.AppLayout-title {
    font-size: 34px;
    font-weight: normal;
    line-height: normal;
    margin-top: 25px;
    display: inline-block;
    letter-spacing: 0em;
    border-bottom: 6px solid #8291C1;
}

.AppLayout-View {
    padding: 20px 0;

}

.breadCrumb-a {
    padding: 25px 0 0px;
    border-bottom: 1px solid #8291C1;
}

.logo-view {
    padding: 10px;
    text-align: center;
}

.logo-view img {
    max-width: 100%;
    height: auto;
}

.text-center {
    text-align: center;
}

.moreBtn {
    width: 218px;
    height: 64px;
    border-radius: 33px;
    opacity: 1;

    background: #FFFFFF;
    color: #5266A5;
    box-sizing: border-box;
    border: 1px solid #5266A5;
}

.logo-all {
    padding: 30px;
}
</style>
