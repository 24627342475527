<template>
<div id="app">
    <div class="homepage">
        <FullScreenCarousel />
        <NavBar :activeTab="activeTab" />
    </div>

    <div class="container aboutBac">
        <div class="about">
            <h1>{{ $t('about') }}</h1>
            <div class="line"></div>
            <p class="about-view" v-html="$t('aboutView')"></p>
            <button class="aboutBtn" @click="goToPressCenter('about')">{{ $t('aboutbtn') }}</button>
        </div>
    </div>
    <div class="layoutBac">
        <!-- <div class="container ">
            <div class="layout">
                <h1 v-intersect="'slideInUp animated'" style="animation-delay: 0s; ">{{ $t('buju') }}</h1>
                <div class="line" v-intersect="'slideInUp animated'" style="animation-delay: 0.2s; "></div>
                <p class="about-view" v-intersect="'slideInUp animated'" style="animation-delay: 0.5s; ">{{ $t('bujuView') }}</p>
                <div class="layout-flex row" v-intersect="'slideInUp animated'" style="animation-delay: 0.8s; ">
                    <div class="col" @click="goToPressCenter('AppLayout')">
                        <img src="@/assets/home-1.png" class="width" alt="" />
                    </div>
                    <div class="col" @click="goToPressCenter('PageLayout')">
                        <img src="@/assets/home-2.png" class="width" alt="" />
                    </div>
                </div>
            </div>
        </div> -->
        <div class="container historyAll" id="contact">
            <div class="layout">
                <h1>{{ $t('buju') }}</h1>
                <div class="line"></div>
                <p class="about-view">{{ $t('bujuView') }}</p>
                <div class="row">
                    <div class="col-md-6 history-all hover-div" @click="goToPressCenter('AppLayout')">
                        <div class="history-all-left">
                            <div class="history-po">
                                <div class="history-title" v-intersect="'slideInUp animated'" style="animation-delay: 0s; ">MANUFACTURING</div>
                                <div class="history-view" v-intersect="'slideInUp animated'" style="animation-delay: 0.3s;     text-align: left;">{{ $t('zhizhao') }}</div>
                                <div class="history-tag" v-intersect="'slideInUp animated'" style="animation-delay: 0.5s; "></div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 history-all hover-div" @click="goToPressCenter('PageLayout')">
                        <div class="history-all-right">
                            <div class="history-po-right">
                                <div class="history-title" v-intersect="'slideInUp animated'" style="animation-delay: 0s;">HIGH TECH ZONE</div>
                                <div class="history-view" v-intersect="'slideInUp animated'" style="animation-delay: 0.3s; text-align: right;">{{ $t('qitalei') }}</div>
                                <div class="history-tag historyadd" v-intersect="'slideInUp animated'" style="animation-delay: 0.5s;"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="container" v-if="currentLanguage !== 'en'">
        <div class="centerH1">
            <h1>最新动态</h1>
            <div class="line"></div>
            <p class="about-view">与您分享紫江的发展点滴</p>
            <div class="row align-items-start">
                <div class="col-md-4" @click="handleClick(index)" v-for="(data, index) in DataView" :key="index">
                    <figure class=" hover-div">
                        <img :src="$imgUrl + (currentLanguage === 'zh' ? data.list_img_cn : data.list_img_en)" class="figure-img img-fluid rounded listimg" alt="...">
                        <figcaption class="text-start start-title">
                            {{ currentLanguage === 'zh' ? data.title_cn : data.title_en }}
                        </figcaption>
                        <p class="text-start start-view">
                            {{ currentLanguage === 'zh' ? data.description_cn : data.description_en }}
                        </p>
                        <p class="text-start text-time">{{ data.release_time }}</p>
                    </figure>
                </div>
                <!-- <div class="col-md-4" @click="goToPressCenter('PressCenterDetails')">
                    <figure class="figure">
                        <img src="@/assets/list.png" class="figure-img img-fluid rounded" alt="...">
                        <figcaption class="text-start start-title">紫竹高新区管理委员会召开2024年第一次会议</figcaption>
                        <p class="text-start start-view">3月14日中午，紫竹高新区管理委员会2024年第一次会议在高新区本部大楼举行。</p>
                        <p class="text-start text-time">2024 - 03 - 16</p>
                    </figure>
                </div>
                <div class="col-md-4" @click="goToPressCenter('PressCenterDetails')">
                    <figure class="figure">
                        <img src="@/assets/list.png" class="figure-img img-fluid rounded" alt="...">
                        <figcaption class="text-start start-title">紫竹高新区管理委员会召开2024年第一次会议</figcaption>
                        <p class="text-start start-view">3月14日中午，紫竹高新区管理委员会2024年第一次会议在高新区本部大楼举行。</p>
                        <p class="text-start text-time">2024 - 03 - 16</p>
                    </figure>
                </div> -->
            </div>
            <button class="aboutBtn " @click="goToPressCenter('PressCenter')">
                更多集团新闻
            </button>
        </div>
    </div>
    <div class="container faz">
        <h1 style="text-align: center;">{{ $t('history') }}</h1>
        <div class="line"></div>
        <p class="about-view">{{ $t('historyView') }}</p>
        <div class="home-rea">
            <!-- <img src="@/assets/home/home-3.png" class="img-fluid" alt="..."> -->
            <div class="po">
                <img src="@/assets/home/year-home.png" class="" alt="...">
                <div class="po-btn hover-div " @click="goToPressCenter('History')">{{ $t('historyBtn') }}</div>
            </div>
        </div>

    </div>
    <FooterBar />
</div>
</template>

<script>
import FullScreenCarousel from '@/components/FullScreenCarousel.vue';
import NavBar from '@/components/NavBar.vue';
import FooterBar from '@/components/FooterBar.vue';
export default {
    name: 'App',
    components: {
        FullScreenCarousel,
        NavBar,
        FooterBar,
    },
    data() {
        return {
            currentLanguage: this.$i18n.locale,
            DataView: '',
            activeTab: 'home',
            Kv:''
            //'home', 'about', 'news', 'contact', 'other'
        };
    },
    created() {
        this.fetchTabsData();
        //  this.fetchKvData();
    },
    watch: {
        '$i18n.locale': function (newLocale) {
            this.currentLanguage = newLocale;
        }
    },
    methods: {
        goToPressCenter(res) {
            window.open("/" + res);
            // this.$router.push("/" + res);
        },
        //获取首页新闻
        async fetchTabsData() {
            this.loading = true;
            try {
                const response = await this.$api.get('/api/news/top', {

                });
                console.log(response.data)
                this.DataView = response.data;
            } catch (error) {
                this.error = 'Failed to load data';
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
       
        //跳转新闻详细
        handleClick(index) {
            console.log(this.DataView[index])
            if (this.DataView[index].type == "wxPage") {
                console.log('wxPage')
                console.log(this.DataView[index].url_cn)
                window.open(this.DataView[index].url_cn, '_blank');
            }
            if (this.DataView[index].type == "video") {
                console.log('video')
                console.log(this.DataView[index].video_url_cn)
                window.open(this.DataView[index].video_url_cn, '_blank');
            }
            if (this.DataView[index].type == "html") {
                let tid = this.DataView[index].n_id;
                console.log(tid);
                let routeData = this.$router.resolve({
                    name: 'PressCenterDetails',
                    query: {
                        tid: tid
                    }
                });
                window.open(routeData.href, '_blank');
            }
        }
        // handleClick(tid) {
        //     console.log("Clicked tid:", tid);
        //     window.open({
        //         name: 'PressCenterDetails', 
        //         query: {
        //             tid
        //         }
        //     });
        // }

    }
};
</script>

<style>
html,
body,
#app {
    height: 100%;
    margin: 0;
    overflow: auto;
}

.homepage {
    position: relative;
}

.about {
    text-align: center;
}

.line {
    margin: 30px auto;
    width: 100px;
    height: 0px;
    opacity: 1;
    border-top: 6px solid #8291C1;
}

.about-view {
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0em;
}

.aboutBtn {
    width: 218px;
    height: 64px;
    border-radius: 33px;
    opacity: 1;
    font-size: 16px;
    font-weight: 500;
    background: #FFFFFF;
    color: #5266A5;
    box-sizing: border-box;
    border: 1px solid #5266A5;
}

.about,
.layout {
    padding: 60px 0;
}

.layout {
    text-align: center;
    background-color: #F5F5F5;
}

.aboutBac {
    background-image: url('@/assets/earthBac.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
}

.layout-flex {}

.width {
    width: 100%;
}

.layoutBac {
    background-color: #F5F5F5;
}

.centerH1 {
    text-align: center;
    padding: 30px 0;
}

.start-title {
    /* H4 */
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    color: #000000;
    border-bottom: 1px solid #B5BEDA;
    padding: 20px 0;
}

.history-all {
    position: relative;
}

.start-view {
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    padding-top: 20px;
}

.text-time {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #5266A5;
    letter-spacing: 0em;
}

.home-rea {
    position: relative;
}

.po {
    position: absolute;
    top: 70px;
    left: 30px;
}

.po img {
    width: 50%;
}

.po-btn {
    width: 150px;
    height: 32px;
    border-radius: 33px;
    opacity: 1;
    background: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0em;
    margin-top: 50px;

}

.faz {
    padding: 60px 0 30px;
}

.aboutBtn {
    margin-top: 60px;
}

.about-view {
    margin-bottom: 30px;
}

.historyAll {
    padding: 60px 0 18px;
}


.history-all-left{
    position: relative;
    height: 350px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.history-all-left::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(@/assets/home/home-a.png) no-repeat center center;
    background-size: cover;
    transition: transform 0.5s ease-in-out;
    z-index: 1;
    border-radius: 10px;
}

.history-all-left:hover::before {
    transform: scale(1.1);
}


.history-all-right {
    position: relative;
    height: 350px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.history-all-right::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('@/assets/home/home-b.png') no-repeat center center;
    background-size: cover;
    transition: transform 0.5s ease-in-out;
    z-index: 1;
    border-radius: 10px;
}

.history-all-right:hover::before {
    transform: scale(1.1);
}

.history-po {
    position: absolute;
    top: 50%;
    left: 60px;
     z-index: 2;
    transform: translateY(-50%);
}

.history-po-right {
    position: absolute;
    top: 50%;
    right: 60px;
    text-align: right;
    transform: translateY(-50%);
    z-index: 2;
}

.history-title {
    font-family: Impact;
    font-size: 50px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz"auto;
    color: #FFFFFF;
    opacity: 0.2;
}

.history-view {
    font-size: 34px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz"auto;
    font-feature-settings: "kern"on;
    color: #FFFFFF;
}

.history-detaills {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz"auto;
    font-feature-settings: "kern"on;
    color: #FFFFFF;
}

.history-tag {
    width: 30px;
    height: 10px;
    background-color: #33A37A;
    margin: 10px 0 30px;
}

.history-more {
    width: 150px;
    height: 32px;
    border-radius: 33px;
    opacity: 1;
    margin-top: 10px;
    line-height: 30px;
    text-align: center;
    background: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
}

.historyadd {
    margin-left: auto;
}

.listimg {

    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

.home-rea {
    width: 100%;
    height: 260px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(84deg, #5266A5 0%, rgba(0, 0, 0, 0) 119%), url(@/assets/home/home-3.png) no-repeat;
    background-size: cover;
}
</style>
