import { render, staticRenderFns } from "./PressCenterDetails.vue?vue&type=template&id=372fb2bf&scoped=true"
import script from "./PressCenterDetails.vue?vue&type=script&lang=js"
export * from "./PressCenterDetails.vue?vue&type=script&lang=js"
import style0 from "./PressCenterDetails.vue?vue&type=style&index=0&id=372fb2bf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "372fb2bf",
  null
  
)

export default component.exports